import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './globals.css';

const RootLayout = React.lazy(() => import('./layouts/root-layout'));
const PublicLayout = React.lazy(() => import('./layouts/public-layout'));
const PrivateLayout = React.lazy(() => import('./layouts/private-layout'));

const IndexPage = React.lazy(() => import('./pages/index'));
const SignInPage = React.lazy(() => import('./pages/sign-in'));
const SignUpPage = React.lazy(() => import('./pages/sign-up'));

const DashboardPage = React.lazy(() => import('./pages/private/dashboard'));
const MovieListsPage = React.lazy(() => import('./pages/private/movie-lists'));
const MovieListUsersPage = React.lazy(
    () => import('./pages/private/movie-list-users'),
);

const router = createBrowserRouter([
    {
        element: <RootLayout />,
        children: [
            {
                element: <PublicLayout />,
                children: [
                    { path: '/', element: <IndexPage /> },
                    { path: '/sign-in/*', element: <SignInPage /> },
                    { path: '/sign-up/*', element: <SignUpPage /> },
                ],
            },
            {
                element: <PrivateLayout />,
                children: [
                    { path: '/dashboard', element: <DashboardPage /> },
                    {
                        path: '/movie-lists/:listId/users',
                        element: <MovieListUsersPage />,
                    },
                    { path: '/movie-lists', element: <MovieListsPage /> },
                ],
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
